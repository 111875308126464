import React, { useEffect, useState } from 'react';
import { PopupCardTaskAnalyzeProcessStatus } from '@bvt-features/mapp/taskAnalyze/component/PopupCardTaskAnalyzeProcessStatus';
import { useMappTaskAnalyze } from '../../hook/useMappTaskAnalyze';
import moment from 'moment';
import { toTitleCase } from '@bvt-utils';
import { useNavigate } from 'react-router';
import { message, notification } from 'antd';
import { useInterval } from 'ahooks';
import { useSound } from '@bvt-hooks';
import { useAuth } from '@bvt-features/auth/hooks';
import { PopupCardSelectCountry } from '../../component/PopupSelectCountry';

export function PopupTaskAnalyzeProcessStatusContainer() {
  const taskAnalyze = useMappTaskAnalyze();
  const AUTH = useAuth();
  const SOUND = useSound(AUTH.state.data?.setting?.sound_url);
  const nav = useNavigate();
  const [prevValue, setPrevValue] = useState([]);

  useInterval(() => {
    taskAnalyze.getListTask({
      limit: 100,
    });
  }, 5000);

  useEffect(() => {
    setPrevValue(taskAnalyze.state?.data);
    if (
      taskAnalyze.state?.data[0]?.status !== prevValue[0]?.status &&
      prevValue.length > 0
    ) {
      let statusFromArray = taskAnalyze.state?.data[0]?.status;
      let nameFromArray = taskAnalyze.state?.data[0]?.project_name;
      let displayFromArray = taskAnalyze.state?.data[0]?.display_name;
      let forgeString = `feature name ${displayFromArray} and project name ${nameFromArray}`;
      SOUND.playSound();
      taskAnalyze.setPopupVisibility(true);
      if (statusMapper(statusFromArray) === 'PROCESING') {
        notification.info({
          message: 'New Proccessing List',
          description: forgeString,
          placement: 'bottomRight',
        });
      }
      if (statusMapper(statusFromArray) === 'FAILED') {
        notification.error({
          message: 'Proccessing Failed',
          description: forgeString,
          placement: 'bottomRight',
        });
      }
      if (statusMapper(statusFromArray) === 'SUCCESS') {
        notification.success({
          message: 'Proccessing Success',
          description: forgeString,
          placement: 'bottomRight',
        });
      }
    }
  }, [taskAnalyze.state.data]);

  useEffect(() => {
    if (taskAnalyze.state?.isPopUpVisible) {
      taskAnalyze.getListTask({
        limit: 100,
      });
    } else {
      taskAnalyze.resetStatus();
    }
  }, [taskAnalyze.state?.isPopUpVisible]);

  useEffect(() => {
    if (
      taskAnalyze.state.status_DELETE === 'SUCCESS' ||
      taskAnalyze.state.status_POST === 'SUCCESS'
    ) {
      taskAnalyze.getListTask({
        limit: 100,
      });
    }
    if (
      taskAnalyze.state.status_DELETE === 'FAILED' ||
      taskAnalyze.state.status_POST === 'FAILED'
    ) {
      message.error(taskAnalyze.state.message);
    }
    if (
      taskAnalyze.state.status_DELETE === 'SUCCESS' ||
      taskAnalyze.state.status_POST === 'SUCCESS'
    ) {
      message
        .loading('On processing')
        .then(() => message.success(taskAnalyze.state.message, 2.5));
    }
  }, [taskAnalyze.state.status_DELETE, taskAnalyze.state.status_POST]);

  useEffect(() => {
    return () => {
      taskAnalyze.resetAll();
    };
  }, []);

  const statusMapper = (status) => {
    switch (status) {
      case 'SUCCESS':
        return 'SUCCESS';
      case 'PROGRESS':
        return 'PROCESING';
      case 'FAILED':
        return 'FAILED';
      case 'CANCELED':
        return 'CANCELED';
      case 'PROCESING':
        return 'WAITING';
      case 'WAITING':
        return 'WAITING';
      case 'WARNING':
        return 'WARNING';
      default:
        return 'FAILED';
    }
  };

  const HandleClick = (v) => {
    if (
      v?.original?.status === 'SUCCESS' ||
      v?.original?.status === 'WARNING'
    ) {
      let urlString = '/app/map/';
      switch (v?.original?.analyze_type) {
        case 'grid_analitic':
        case 'grid_analitic_v3':
          urlString += 'map-analytic/grid-analysis?';
          urlString += v?.original?.project_id
            ? 'project_id=' + v?.original?.project_id
            : '';
          // urlString += v?.original?.step_order
          //   ? '&step=' + v?.original?.step_order
          //   : '&step=6';
          // TODO: HARDCODE FIX ASLI
          urlString += '&step=6';
          urlString += v?.original?.UUID ? '&process=' + v?.original?.UUID : '';
          nav(urlString);
          break;
        case 'site_profiling':
        case 'site_profiling_v3':
          urlString += 'map-analytic/site-profiling?';
          urlString += v?.original?.project_id
            ? 'project_id=' + v?.original?.project_id
            : '';
          urlString += '&step=7';
          urlString += v?.original?.UUID ? '&process=' + v?.original?.UUID : '';
          nav(urlString);
          break;
        case 'site_profiling_int_jpn':
        case 'site_profiling_int_vn':
          urlString += 'map-analytic/site-profiling-mandala?';
          urlString += v?.original?.project_id
            ? 'project_id=' + v?.original?.project_id
            : '';
          urlString += '&step=7';
          urlString += v?.original?.UUID ? '&process=' + v?.original?.UUID : '';
          nav(urlString);
          break;
        default:
          message.error('Feature Not yet Developed');
          break;
      }
    } else if (
      v?.original?.status === 'PROGRESS' ||
      v?.original?.status === 'PROCESING'
    ) {
      taskAnalyze.cancelTask(v?.original?.UUID, v?.original?.analyze_type);
    } else {
      taskAnalyze.deleteTask(v?.original?.UUID);
    }
    taskAnalyze.setPopupVisibility(false);
  };

  return (
    <div style={{ display:'flex',height:'100%',padding:'0px' }}>
      <PopupCardTaskAnalyzeProcessStatus
        data={taskAnalyze.state?.data?.map((v) => ({
          id: v?.UUID,
          projectType: toTitleCase((v?.analyze_type || '').split('_').join(' ')),
          projectName: v?.project_name,
          elapsedTime: moment(v?.created_at).fromNow(),
          status: statusMapper(v?.status),
          original: { ...v },
        }))}
        isLoading={
          taskAnalyze.state.status_GET === 'LOADING' ||
            taskAnalyze.state.status_POST === 'LOADING' ||
            taskAnalyze.state.status_DELETE === 'LOADING'
        }
        onClick={(v) => HandleClick(v)}
        onTogglePop={() =>
          taskAnalyze.setPopupVisibility(!taskAnalyze.state?.isPopUpVisible)
        }
        visible={taskAnalyze.state?.isPopUpVisible}
      />
      {process.env.MODE !== 'production' && <PopupCardSelectCountry/>}
    </div>
  );
}
